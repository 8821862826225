import { useState } from 'react';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';

import { MobileMenu } from '../mobile-menu/mobile-menu';
import { NavigationDesktop } from '../navigation-desktop/navigation-desktop';
import { NavigationMobile } from '../navigation-mobile/navigation-mobile';
import { NavigationWrapper } from '../navigation-wrapper/navigation-wrapper';

import { StyledNav } from './nav.styled';

export interface NavigationOpenState {
  desktopMenu: boolean;
  mobileMenu: boolean;
  desktop: string;
  mobile: string;
}

export const Nav: React.FC = () => {
  const defaultOpenSettings: NavigationOpenState = {
    desktopMenu: false,
    mobileMenu: false,
    desktop: '',
    mobile: '',
  };

  const { toggle } = useMarketPicker();

  const [isOpen, setIsOpen]
    = useState<NavigationOpenState>(defaultOpenSettings);
  const { menu, global } = useGlobal();

  const toggleMenu = () => {
    const open = !isOpen.mobileMenu;
    setIsOpen({ ...defaultOpenSettings, mobileMenu: open });
  };

  function toggleMarketPicker() {
    toggle(true);
  }
  return (
    <StyledNav>
      <NavigationWrapper isOpen={isOpen.desktopMenu}>
        <NavigationMobile
          setIsOpen={setIsOpen}
          defaultOpenSettings={defaultOpenSettings}
          toggleMenu={toggleMenu}
          toggleMarketPicker={toggleMarketPicker}
        />
        <NavigationDesktop
          setIsOpen={setIsOpen}
          defaultOpenSettings={defaultOpenSettings}
          isOpen={isOpen}
          toggleMarketPicker={toggleMarketPicker}
        />
      </NavigationWrapper>
      <MobileMenu
        isOpen={isOpen.mobileMenu}
        toggleMenu={toggleMenu}
        headerText={global.menu || ''}
        menuItems={menu.menuItems}
        partnerPortalUrl={global.partnerPortalUrl || ''}
        partnerLoginLabel={global.partnerPortalLabel || ''}
      />
    </StyledNav>
  );
};
