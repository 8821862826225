import { Dispatch, Fragment, SetStateAction } from 'react';

import Link from 'next/link';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import { MenuItemFragment } from '@hultafors/hultaforsgroup/types';

import LogoSvg from '../../svg/logo.svg';
import PartnerSvg from '../../svg/partner.svg';
import { DesktopMenu } from '../desktop-menu/desktop-menu';
import { NavigationOpenState } from '../nav/nav';
import { SlideInToggler } from '../slide-in/slide-in';

import LanguageSvg from './language.svg';
import {
  IconLabel,
  Logo,
  NavButton,
  NavigationDesktopWrapper,
  NavLink,
  PartnerLink,
  TogglerButton,
  TopActions,
  TopMenu,
} from './navigation-desktop.styled';

interface NavigationDesktopProps {
  setIsOpen: Dispatch<SetStateAction<NavigationOpenState>>;
  defaultOpenSettings: NavigationOpenState;
  isOpen: NavigationOpenState;
  toggleMarketPicker(): void;
}

export const NavigationDesktop: React.FC<NavigationDesktopProps> = ({
  setIsOpen,
  defaultOpenSettings,
  isOpen,
  toggleMarketPicker,
}) => {
  const { global, menu } = useGlobal();
  const toggleDesktopMenu: SlideInToggler = (type) => {
    if (type) {
      const open = !isOpen.desktopMenu;
      if (type === isOpen.desktop) {
        type = '';
      }
      setIsOpen({ ...defaultOpenSettings, desktopMenu: open, desktop: type });
    } else {
      setIsOpen({
        ...defaultOpenSettings,
        desktopMenu: false,
        desktop: '',
      });
    }
  };

  const topMenuMapper = (item: MenuItemFragment) => {
    const toggleMainMenu = () => toggleDesktopMenu('main');
    if (!item.submenu) {
      return (
        <NavLink
          href={item.url || ''}
          key={`MenuItemLink-${item.id}`}
          onClick={toggleMainMenu}
        >
          {item.title}
        </NavLink>
      );
    }

    if (item?.title) {
      return (
        <Fragment key={`MenuItem-${item.id}`}>
          <NavButton
            onClick={() => toggleDesktopMenu(item.title)}
            $active={isOpen.desktop === item.title}
            data-slidein-ignore="desktopMenu"
          >
            {item.title}
          </NavButton>
          <DesktopMenu
            openType={isOpen.desktop}
            menuItems={item.submenu.menuItems}
            toggleDesktopMenu={toggleDesktopMenu}
            partnerPortalUrl="/partner-portal"
            menuType={item.title || ''}
          />
        </Fragment>
      );
    }

    return null;
  };

  return (
    <NavigationDesktopWrapper
      className="DesktopNav"
      data-test="NavigationDesktop"
    >
      <Logo>
        <Link
          href="/"
          aria-label="Hultafors Group"
          onClick={() => setIsOpen({ ...defaultOpenSettings })}
        >
          <LogoSvg aria-hidden={true} focusable={false} height={14} />
        </Link>
      </Logo>
      <TopMenu>{menu.menuItems.map(topMenuMapper)}</TopMenu>
      <TopActions>
        <TogglerButton
          onClick={toggleMarketPicker}
          aria-label={global?.changeLanguage || ''}
        >
          <LanguageSvg width={24} height={24} color="white" />
          <IconLabel>{global?.changeLanguage}</IconLabel>
        </TogglerButton>
        {global.partnerPortalUrl && (
          <PartnerLink href={global.partnerPortalUrl}>
            <PartnerSvg
              width={24}
              height={24}
              aria-hidden={true}
              focusable={false}
              color="white"
            />
            <IconLabel>{global?.partnerPortalLabel}</IconLabel>
          </PartnerLink>
        )}
      </TopActions>
    </NavigationDesktopWrapper>
  );
};
